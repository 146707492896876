:root {
  --main-accent-color: #343d46;
  --main-accent-darker-color: #141d26;
  --main-accent-lighter-color: #747d86;
  --main-bg-color: white;
  --main-bg-text-color: var(--main-accent-color);
  --main-bg-text-active-color: var(--main-accent-color);
  --accent-bg-color: var(--main-accent-color);
  --accent-bg-text-color: #eee;
  --indent-bg-color: #f7f7f7;
  --indent-bg-text-color: black;
  --site-width: 60rem;
  --height-top-bar: 5rem;
  --logo-color: var(--main-accent-color);
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

body {
  background-color: var(--main-bg-color);
  color: var(--main-bg-text-color);
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] minmax(0, var(--site-width)) [main-end] minmax(0, 1fr) [full-end];
  min-width: 370px;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3rem;
  display: grid;
}

body > div {
  display: inherit;
  grid-column: full;
  grid-template-columns: inherit;
}

body > div > * {
  grid-column: main;
  padding-left: 1rem;
  padding-right: 1rem;
}

#topbar {
  justify-content: space-between;
  display: flex;
}

#topbar > .logo {
  line-height: var(--height-top-bar);
}

#topbar > .logo > a {
  color: var(--logo-color);
  height: var(--height-top-bar);
  font-family: Sarina, cursive;
  font-size: 2rem;
  text-decoration: none;
  display: inline-block;
}

#topbar > ul {
  list-style: none;
}

#topbar > ul > li {
  line-height: var(--height-top-bar);
  margin-left: .7rem;
  display: inline-block;
}

#topbar > ul > li > a {
  color: var(--main-accent-lighter-color);
  height: var(--height-top-bar);
  text-decoration: none;
  display: inline-block;
}

#topbar > ul > li > a:hover {
  color: var(--main-bg-text-active-color);
}

#intro {
  text-align: center;
  align-self: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

#intro > h1 {
  margin: 3.5rem 0;
  font-size: 4rem;
  font-weight: 300;
}

#intro > h2 {
  max-width: 80%;
  margin: 3rem auto;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.2rem;
}

#intro > a.cta {
  color: var(--main-accent-darker-color);
  border: 1px solid var(--main-accent-darker-color);
  padding: 1rem 1.3rem;
  font-size: 1.3rem;
  text-decoration: none;
  display: inline-block;
}

#intro > a.cta:hover {
  background-color: var(--main-accent-darker-color);
  color: #fff;
}

#above-the-fold {
  display: flex;
  padding-top: 2rem !important;
  padding-bottom: 5rem !important;
}

#above-the-fold > ul {
  flex: 1;
}

#above-the-fold > ul > li {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-right: 2rem;
}

#above-the-fold > ul > li > h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 300;
}

#above-the-fold > ul > li > p > a {
  color: var(--main-accent-color);
}

#above-the-fold > ul > li > p > a:hover {
  color: var(--main-accent-darker-color);
}

#above-the-fold .example-vertical {
  flex: 1;
}

#explainer {
  align-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 1.5rem;
}

#explainer > h1 {
  margin: .5rem 0;
  font-size: 2rem;
  font-weight: 300;
}

#explainer > p {
  padding: .5rem 0;
}

#explainer > p > a {
  color: var(--main-accent-text-color);
  font-weight: 400;
  text-decoration: none;
}

#explainer > p > a:hover {
  text-decoration: underline;
}

#examples {
  padding-bottom: 5rem;
}

#examples article {
  margin-bottom: 6rem;
  display: flex;
}

#examples .example-description {
  flex: 3;
  margin-left: 2rem;
}

#examples h1 {
  margin: 4rem 0 2rem;
  font-size: 2rem;
  font-weight: 300;
}

#examples h2 {
  margin-bottom: 1rem;
  font-size: 1.4px;
  font-weight: 300;
}

#examples .example-vertical {
  border-top: 1px solid var(--main-accent-color);
  flex: 4;
}

.example-vertical .variables {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid var(--main-accent-color);
  grid-row-gap: .5rem;
  grid-template-rows: auto;
  grid-template-columns: 2fr 5fr;
  display: grid;
}

.example-vertical .results {
  margin-top: 2rem;
}

article p {
  margin-bottom: 1rem;
}

article code {
  background-color: var(--accent-bg-text-color);
}

footer {
  color: #818a91;
  margin-bottom: 2rem;
  font-size: .8rem;
}

footer li {
  margin-right: 1rem;
  display: inline;
}

dl.variables {
  background-color: var(--indent-bg-color);
  padding: 1rem;
  font: 14px Monaco, Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace;
}

dl.variables > dt {
  color: var(--code-variable-color);
}

dl.variables > dd {
  display: flex;
}

dl.variables > dd > input {
  width: 80%;
}

dl.variables > dd > input[type="range"] {
  height: 1.3rem;
}

dl.variables > dd > label {
  padding: 0 .5rem;
}

dl.variables > dd.radio {
  grid-template-rows: auto;
  grid-template-columns: 1fr 9fr;
  display: grid;
}

.output {
  grid-row-gap: .5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  display: grid;
}

.output > dt {
  color: var(--code-id-color);
}

.output > dd {
  color: var(--code-string-color);
}

.annotations {
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  align-items: center;
  padding-top: 1rem;
  display: grid;
}

.annotation__name {
  color: var(--code-error-color);
}

.annotation__body {
  font-size: .7rem;
}

.annotation__slice, .annotation__label {
  white-space: pre-wrap;
  color: #999;
  margin: 0;
}

.annotation__name, .annotation__label {
  color: #d14;
}

@media (width <= 60rem) {
  :root {
    --height-top-bar: 8vw;
  }

  main {
    display: block;
  }

  #examples {
    display: none;
  }
}

@media (width <= 30rem) {
  #topbar {
    justify-content: end;
  }

  #topbar > .logo {
    display: none;
  }

  #topbar > ul > li {
    margin-left: .2rem;
  }
}
/*# sourceMappingURL=index.74f83d51.css.map */
